<template>
  <!-- <div :style="{ 'custom-hostname': backgroundColor }"> -->
    <loader/>
    <my-dialog v-if="!$store.state.cookie">
      <cookie-policy/>
      <my-button @click="ACCEPT_COOKIE">Принять</my-button>
    </my-dialog>
    <router-view :key="$route.path"></router-view>
    <catalog-filter :is-open="$store.state.isPopupOpen"/>
    <my-svg/>
    <agency-logo/>
  <!-- </div> -->
</template>

<style>
@import "@/assets/shop-design/css/style.css";
</style>

<script>
import {mapActions} from "vuex";
import {defineAsyncComponent} from "vue";

import CookiePolicy from '@/views/components/CookiePolicy.vue';

const CatalogFilter = defineAsyncComponent({
  loader: () => import("@/views/components/Modals/CatalogFilter.vue"),
});
// const AgencyLogo = null;

const AgencyLogo = (window.location.hostname === 'hendlex.it-shop-tg.ru' || window.location.hostname === 'fox.it-shop-tg.ru' || window.location.hostname === 'd3.it-shop-tg.ru' || window.location.hostname === 'blumarine.it-shop-tg.ru') ? null : defineAsyncComponent({
  loader: () => import("@/views/components/AgencyLogo.vue"),
});


export default {
  components: {
    CatalogFilter,
    AgencyLogo,
    CookiePolicy,
    // data() {
    //   return {
    //     backgroundColor: "",
    //   };
    // },
  },
  methods: { 
    ...mapActions(["GET_RAZDELS_LIST", "GET_APP_INFO", "SYNC_LOCAL_DATA", "SYNC_GLOBAL_DATA", "ACCEPT_COOKIE", "CHECK_COOKIE"]),
    telegramInit() {
      //отлавливаем собыие когда телеграм готов к работе
      Telegram.WebApp.ready();

      Telegram.WebApp.MainButton.setParams({
        text: "ОФОРМИТЬ ЗАКАЗ",
      });

      if (typeof Telegram.WebApp.initDataUnsafe.user !== "undefined") {
        this.$store.state.$app_mode = 1;
        //расширяем на все окно
        Telegram.WebApp.expand();
      } else {
        this.$store.state.$app_mode = 2;
      }
    },
    telegramAuthState() {
      if (this.$store.state.$app_mode === 1) {
        this.$store.commit("setUserId", "");
        let dataAuth = {};
        if (typeof Telegram.WebApp.initDataUnsafe.start_param !== "undefined") {
          dataAuth = {
            ...Telegram.WebApp.initDataUnsafe.user,
            ...{start_param: Telegram.WebApp.initDataUnsafe.start_param},
          };
        } else {
          dataAuth = Telegram.WebApp.initDataUnsafe.user;
        }
        this.$auth(dataAuth);
      } else {
        //авторизация через браузер
      }
    },
    setStylesForHostname() {
      document.documentElement.style.setProperty('--tg-theme-bg-color', '#ffffff');
    }
    // async fetchBackground() {
    //   try {
    //     const response = await this.$http.post("api/styles", {
    //       action: "change_color",
    //       params: {
    //         type: "background",
    //       },
    //     });
    //    ;
    //     this.backgroundColor = response.data.data.color;
    //   } catch (error) {
    //     console.error("Ошибка при запросе на сервер:", error);
    //   }
    // },
  },
  mounted() {
    const hostname = window.location.hostname;
    if (hostname === 'localhost') this.setStylesForHostname();
    if (hostname === 'trueberry.it-shop-tg.ru') this.setStylesForHostname();
    if (hostname === 'blumarine.it-shop-tg.ru') this.setStylesForHostname();

    // this.fetchBackground();
    this.SYNC_GLOBAL_DATA();
    this.GET_RAZDELS_LIST();
    this.GET_APP_INFO();
    this.CHECK_COOKIE();
    this.telegramInit();
    this.telegramAuthState();

    if (hostname === 'gagaring.it-shop-tg.ru'){
      const vuexData = JSON.parse(localStorage.getItem('vuex'));
      if (vuexData && vuexData.cart && vuexData.cart.length > 0 && !vuexData.updated) {
        vuexData.cart = [];
        vuexData.updated = true;
        localStorage.setItem('vuex', JSON.stringify(vuexData));
      }
    }
    
  },
  updated() {
    if (this.$store.state.$app_mode === 1) {
      let curPage = this.$route.name;
      let cart = this.$store.state.cart;
      if (curPage === "cart" && cart.length > 0) {
        Telegram.WebApp.MainButton.show();
      } else {
        Telegram.WebApp.MainButton.hide();
      }
    }
  },
};
</script>