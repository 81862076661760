<template>
  <button class="selectgroup-button">
    <slot/>
  </button>
</template>

<script>
export default {
  name:'my-button'
}
</script>

<style scoped>
.selectgroup-button{
  margin-top: 10px;
}
</style>